// Funciones de utilidad
// 

export default class FuncLib {

    CodUbiDecript(ubicacion: string): string {
        if (!ubicacion) return '';

        var result: string = '';
        const separatedString = ubicacion.match(/.{0,1}/g);

        if (separatedString !== null) {
            for (let i = 0; i < separatedString.length; i++) {
                if (i === 2) result += "Almacen: " + separatedString[0] + separatedString[1];
                if (i === 4) result += ", Pasillo: " + separatedString[2] + separatedString[3];
                if (i === 6) result += ", Posición: " + separatedString[4] + separatedString[5];
                if (i === 8) result += ", Altura: " + separatedString[6] + separatedString[7];
                if (i === 10) result += ", Tipo: " + separatedString[8] + separatedString[9];
                if (i === 12) result += ", Caja: " + separatedString[10] + separatedString[11];
            }
        }
        return result;
    }

    // Función para poner puntos en la ubicación
    punteaUbicacion(input: string): string {
        return input.replace(/(.{2})(?=.)/g, "$1.");
    }

}
