
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask'


// Módulos 
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';

import { PagesModule } from './pages/pages.module';
import { PagesPdaModule } from './pages-pda/pages-pda.module';


import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Services 
import { AuthInterceptor } from './services/interceptors/AuthInterceptor.service';

// Componentes
import { AppComponent } from './app.component';
import { Page404Component } from './page404/page404.component';
import { HomeComponent } from './home/home.component'; 


registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    HomeComponent, 
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    PagesModule,
    PagesPdaModule,
    SharedModule,
 
    HttpClientModule, // Usado en Interceptor,
    NgxMaskModule.forRoot(),

    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      progressAnimation: 'decreasing',
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      enableHtml: true,
    }),

  ],

  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

