import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';

// Interfaces / Modelos
import { IApiResp } from 'src/app/interfaces/api-resp';
import { ICliente } from 'src/app/interfaces/cliente';

// Servicios
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';


@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista() {
    // Devulve la lista
    const path = `${this.apiURL}/clientes/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getCliente(id: number) {
    // Devulve la lista
    const path = `${this.apiURL}/clientes/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  newCli(formData: any) {
    const path = `${this.apiURL}/clientes/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  actualizaCli(cliente: ICliente) {
    const path = `${this.apiURL}/clientes/update/${cliente.id}`;
    return this.http.post<IApiResp>(path, cliente)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  // Actualizar datos de facturación
  actualizaCliFac(cliente: ICliente) {
    const path = `${this.apiURL}/clientes/updateFac/${cliente.id}`;
    return this.http.post<IApiResp>(path, cliente)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  // Actualizar datos de acceso aIntranet
  actualizaCliIntranet(cliente: ICliente) {
    const path = `${this.apiURL}/clientes/updateIntranet/${cliente.id}`;
    return this.http.post<IApiResp>(path, cliente)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )

  }
  deleteCli(id: number) {
    const path = `${this.apiURL}/clientes/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }




  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
