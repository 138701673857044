import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioService } from './datos/usuario.service';
import { environment } from 'src/environments/environment';

/**
 * Servicio para montar el menú de opciones
 */

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  $obserbable = new Observable;
  menu: any[] = [];

  constructor(
    private usuarioSrv: UsuarioService
  ) {
  }


  cargarMenu() {

    this.menu = [];
    const rol = this.usuarioSrv.userdata.rol || 'usuario';

    // Que puede ver en función de su rol:
    const verMaestros = ['jefeAlmacen', 'admin', 'sa'];
    const verTablas = ['jefeAlmacen', 'admin', 'sa'];
    const verClientes = ['cliente', 'admin', 'sa'];
    const verGestion = ['jefeAlmacen', 'admin', 'sa'];
    const verAdministracion = environment.rolesAdministracion;


    // Menú global 
    this.menu.push({
      titulo: 'Panel',
      icono: 'fa-solid fa-gauge',
      submenu: [
        { titulo: 'Estado de almacenes', url: '/' },
      ]
    });


    // Clientes
    this.menu.push({
      titulo: 'Clientes',
      icono: 'fa-solid fa-people-line',
      submenu: [
        { titulo: 'Clientes', url: 'clientes_lista' },
        { titulo: 'Facturar', url: 'cliente_facturar' },
        { titulo: 'Historico Facturación', url: 'cliente_fac_list' },
      ]
    });


    // Articulos
    this.menu.push({
      titulo: 'Articulos',
      icono: 'fa-solid fa-shapes',
      submenu: [
        { titulo: 'Artículos', url: 'articulos_lista' },
      ]
    });


    // Stock
    this.menu.push({
      titulo: 'Stock',
      icono: 'fa-solid fa-cubes',
      submenu: [
        { titulo: 'Stock', url: 'stock_lista' },
        { titulo: 'Stock Histórico', url: 'stock_lista_historico' },
        { titulo: 'Transacciones', url: 'transacciones' },
      ]
    });



    // Entradas
    this.menu.push({
      titulo: 'Entradas',
      icono: 'fa-solid fa-truck-arrow-right',
      submenu: [
        { titulo: 'Pendientes', url: 'entradas_lista' },
        { titulo: 'No Confirmadas', url: 'entradas_ubicadas_lista/1' },
        { titulo: 'Finalizadas PDA', url: 'entradas_ubicadas_lista/2' },
      ]
    });


    // Pedidos
    this.menu.push({
      titulo: 'Pedidos',
      icono: 'fa-solid fa-clipboard-check',
      submenu: [
        { titulo: 'Campañas', url: 'campanyas_lista' },
        { titulo: 'Cargar Campaña', url: 'campanya_new' },
        { titulo: 'Consulta de pedidos', url: 'pedidos_lista' },
        { titulo: 'Trazabilidad de Lotes', url: 'traza_lista' },
        { titulo: 'Incidencias en recogidas', url: 'preparaciones_incidencias_list' },
        { titulo: 'Etiquetas UIC', url: 'uic-labels' },
        { titulo: 'Info UIC', url: 'uic-info' },
      ]
    });


    // Preparaciones
    this.menu.push({
      titulo: 'Preparaciones',
      icono: 'fa-solid fa-clipboard-check',
      submenu: [
        { titulo: 'Preparación de oleada', url: '' },
        { titulo: 'Pedidos simultáneamente a recogida', url: '' },
        { titulo: 'Cross Docking', url: '' },
      ]
    });


    // Ubicaciones
    if (verTablas.includes(rol)) {
      this.menu.push({
        titulo: 'Ubicaciones',
        icono: 'fa-solid fa-table-list',
        submenu: [
          { titulo: 'Almacenes', url: 'almacenes' },
          { titulo: 'Ubicaciones', url: 'ubicaciones' },
          { titulo: 'Etiquetas Ubicaciones', url: 'ubicaciones_labels' },
          { titulo: 'Tipos de ubicación', url: 'ubi_tipos' },
        ]
      });
    }


    // opciones de Administración 

    if (verAdministracion.includes(rol)) {
      this.menu.push({
        titulo: 'Administración',
        icono: 'fa-solid fa-gears',
        submenu: [
          { titulo: 'Usuarios', url: 'usr_lista' },
          { titulo: 'Info server', url: 'info' },
        ]
      });
    }


  }

}
